import React from 'react';

const LoadingComponent = ({isLoading, error}) => {
    return (
        <div>
            {isLoading || error
                ? <div style={{textAlign: 'center'}}>
                    {isLoading
                        ? <div>Loading...</div>
                        : null
                    }

                    {error
                        ? <div>Error: {error}</div>
                        : null
                    }
                </div>
                : null
            }
        </div>
    );
};

export default LoadingComponent;
