import React, {useEffect, useRef, useState} from 'react';
import numeral from 'numeral';
import CoinService from "../../service/coin.service";
import {Chart, registerables} from "chart.js";
import {Line, Bar} from "react-chartjs-2";
import {AppType, Period} from "../../helper/models";
import {getCurrencyValueByKey} from "../../constants/constants";

Chart.register(...registerables);


const ChartPage = ({setVisible, appType, coinData, period, currency}) => {
    const [isChartLoading, setIsChartLoading] = useState(false);
    const [error, setError] = useState(null);
    const [chartData, setChartData] = useState(null);
    const [crossDate, setCrossDate] = useState(null);
    // const [crossPrice, setCrossPrice] = useState(null);

    const [chartPeriod, setChartPeriod] = useState(period);
    const chartRef = useRef(null);

    const [chartMax, setChartMax] = useState({
        val: null,
        used: false
    });
    const [chartMin, setChartMin] = useState({
        val: null,
        used: false
    });


    useEffect(() => {
        getChartData();
    }, [chartPeriod]);

    function formatDate(element) {

        const date = new Date(element * 1000);
        //var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
        var months = ['01','02','03','04','05','06','07','08','09','10','11','12'];

        const day = date.getDate();
        const month = months[date.getMonth()];
        const year = date.getFullYear();
        const hours = date.getHours();
        const minutes = "0" + date.getMinutes();

        return `${day}.${month}.${year} ${hours}:${minutes.substr(-2)}`;
    }

    function roundToThreeSignificantDigits(num) {
        const numStr = num.toString();

        // Разделяем число на целую и дробную части
        const [integerPart, fractionalPart] = numStr.split('.');

        // Если нет дробной части, возвращаем число как есть
        if (!fractionalPart) {
            return numStr;
        }

        // Регулярное выражение для выделения трех значащих цифр после точки
        const match = fractionalPart.match(/^(0*\d{1,3})(0*)/);

        if (match) {
            const significantDigits = match[1];  // Первые значащие цифры
            const trailingZeros = match[2];      // Последующие нули

            // Формируем окончательное число
            return `${integerPart}.${significantDigits}${trailingZeros}`;
        }

        // Если дробная часть не соответствует формату, возвращаем число как есть
        return numStr;
    }

    async function getChartData() {
        setIsChartLoading(true);
        setError(null);
        const coinId = coinData.id;

        try {
            const response = await CoinService.getCoinChart(appType, coinId, chartPeriod, currency);
            const formattedResponse = response.map(e => {
                // e.p = roundToThreeSignificantDigits(e.p);
                return e;
            });

            let tempMax = null;
            let tempMin = null;
            formattedResponse.forEach(e => {
                if (tempMax == null || tempMax < e.p) {
                    tempMax = e.p
                }
                if (tempMin == null || tempMin > e.p) {
                    tempMin = e.p
                }
            });

            setChartMax(tempMax)
            setChartMin(tempMin)

            const data = {
                // labels: response.data.map(e => {
                labels: formattedResponse.map(e => {
                    return formatDate(e.t);
                }),
                datasets: [{
                    // data: response.data.map(e => e.p),
                    data: formattedResponse.map(e => {
                        return e.p;
                    }),
                    datalabels: {
                        align: 'start',
                        anchor: 'start'
                    },
                    backgroundColor: (context) => {

                        if (!context.chart.chartArea) {
                            return;
                        }

                        const {ctx, data, chartArea: {top, bottom}} = context.chart;

                        var gradient = ctx.createLinearGradient(0, top, 0, bottom);
                        gradient.addColorStop(0, 'rgba(0, 119, 221, 1)');
                        gradient.addColorStop(1, 'rgba(0, 119, 221, 0)');

                        return gradient;

                    },
                    borderColor: 'white',
                    borderWidth: 1,
                    fill: true,
                    pointRadius: 0
                }],
            }

            setChartData(data);

        } catch (e) {
            console.log(e.message)
            setError(e.message);
            setChartData(null);
            setCrossDate(null);
            setChartMax({
                val: null,
                used: false
            });
            setChartMin({
                val: null,
                used: false
            });
        }

        setIsChartLoading(false);
    }

    // const scatterDataLabels = {
    //     id: 'scatterDataLabels',
    //     afterDatasetsDraw(chart, args, options, cancelable, k1, k2, k3) {
    //         const {ctx} = chart;
    //         ctx.save()
    //
    //         for (let x = 0; x< chart.config.data.datasets.length; x++) {
    //             console.log('chart.config.data.datasets[x] = ', chart.config.data.datasets[x])
    //         }
    //     }
    // }

    function getChartTooltipPositionX(chartWidth, x, chartValueWidth) {
        if (x+chartValueWidth/2 > chartWidth) {
            return chartWidth-chartValueWidth/2;
        } else if (x-chartValueWidth/2 < 0) {
            return chartValueWidth/2;
        }
        return x;
    }

    function getChartLabelsPositionX(chartWidth, x, chartValueWidth) {
        if (x < 5) {
            return 5;
        } else if (x+chartValueWidth > chartWidth) {
            return chartWidth-chartValueWidth-5;
        }
        return x;
    }

    let crossHair;
    const crosshairLabel = {
        id: "crosshairLabel",
        afterDatasetsDraw(chart, args, options, cancelable, k1, k2, k3) {
            const {ctx, chartArea: {left, top, right, bottom}, scales: {x, y}} = chart;

            let chartMaxIsDisplayed = false
            let chartMinIsDisplayed = false

            ctx.font = 'bold 16px Verdana,Arial,sans-serif'

            for (let x = 0; x < chart.config.data.datasets.length; x++) {
                for (let i = 0; i < chart.config.data.datasets[x].data.length; i++) {
                    const chartValue = chart.config.data.datasets[x].data[i]
                    const chartValueForDisplay = roundToThreeSignificantDigits(chart.config.data.datasets[x].data[i])

                    if (chartValue == chartMax && !chartMaxIsDisplayed) {
                        const chartValueForDisplayMeasure = ctx.measureText(chartValueForDisplay);
                        const chartLabelsPositionX = getChartLabelsPositionX(chart.width, chart.getDatasetMeta(x).data[i].x, chartValueForDisplayMeasure.width)
                        // ctx.fillStyle = 'white'
                        ctx.fillStyle = 'rgba(0, 203, 0, 1)'
                        ctx.fillText(chartValueForDisplay, chartLabelsPositionX, chart.getDatasetMeta(x).data[i].y+14)
                        chartMaxIsDisplayed = true

                        ctx.fillRect(chart.getDatasetMeta(x).data[i].x-3, chart.getDatasetMeta(x).data[i].y-3, 6, 6)
                    }
                    if (chartValue == chartMin && !chartMinIsDisplayed) {
                        const chartValueForDisplayMeasure = ctx.measureText(chartValueForDisplay);
                        const chartLabelsPositionX = getChartLabelsPositionX(chart.width, chart.getDatasetMeta(x).data[i].x, chartValueForDisplayMeasure.width)
                        // console.log('chartLabelsPositionX 2= ', chartLabelsPositionX)
                        // ctx.fillStyle = 'white'
                        // ctx.fillRect(chartLabelsPositionX, chart.getDatasetMeta(x).data[i].y-18, chartValueMeasure.width, 18)
                        ctx.fillStyle = '#f66'
                        ctx.fillText(chartValueForDisplay, chartLabelsPositionX, chart.getDatasetMeta(x).data[i].y)
                        chartMinIsDisplayed = true

                        ctx.fillRect(chart.getDatasetMeta(x).data[i].x-3, chart.getDatasetMeta(x).data[i].y-3, 6, 6)
                    }
                }
            }

            if (crossHair) {
                ctx.save()
                ctx.beginPath()
                ctx.lineWidth = 2
                ctx.strokeStyle = 'grey'
                ctx.fillStyle = 'grey'
                crossHair.forEach((line) => {
                    ctx.moveTo(line.startX, line.startY)
                    ctx.lineTo(line.endX, line.endY)
                })
                ctx.stroke()
                const tooltipValue = `${roundToThreeSignificantDigits(y.getValueForPixel(crossHair[0].startY))}${getCurrencyValueByKey(currency)}`
                const tooltipValueMeasureWidth = ctx.measureText(tooltipValue).width + 10;
                const tooltipValuePositionX = getChartTooltipPositionX(chart.width, crossHair[1].startX, tooltipValueMeasureWidth)

                ctx.fillRect(tooltipValuePositionX-tooltipValueMeasureWidth/2, 0 , tooltipValueMeasureWidth, 20)
                ctx.textAlign = 'center'
                ctx.fillStyle = 'white'

                ctx.fillText(tooltipValue, tooltipValuePositionX, 18)


                ctx.restore()
            }
        },

        afterEvent(chart, args, options, a, b, c, event) {
            const {ctx, chartArea: {left, top, right, bottom}, scales: {x, y}} = chart;

            const xCoor = args.event.x;
            const yCoor = args.event.y;

            const elements = chart.getElementsAtEventForMode(args.event, 'nearest', { axis: "x" }, false);

            const el = elements[0]?.element.y;
            let tempCrossDate = null;
            // let tempCrossPrice = null;

                if (elements.length) {

                    // Получаем первую ближайшую точку
                    const closestElement = elements[0];
                    // const datasetIndex = closestElement.datasetIndex;
                    const index = closestElement.index;
                    // const priceValue = chartData.datasets[datasetIndex].data[index];
                    const dateValue = chartData.labels[index];
                    // tempCrossPrice = priceValue;
                    tempCrossDate = dateValue;
                }

            if (!args.inChartArea && crossHair) {
                crossHair = null;
                setCrossDate(null);
                // setCrossPrice(null);
            } else if (args.inChartArea) {
                crossHair = [
                    {
                        startX: left,
                        startY: el,
                        endX: right,
                        endY: el, // 268.6507936507935
                    },
                    {
                        startX: xCoor,
                        startY: top,
                        endX: xCoor,
                        endY: bottom,
                    }
                ];
                setCrossDate(tempCrossDate);
                // setCrossPrice(tempCrossPrice);
            }
            args.changed = true;
        }
    }

    const options = {
        maintainAspectRatio: false,
        responsive: true,
        elements: {
            line: {
                tension : 0.1  // smooth lines
            },
        },
        scales: {
            x: {
                display: false,
                    border: {
                    display: false,
                },
                grid: {
                    display: false
                }
            },
            y: {
                display: false,
                    border: {
                    display: false,
                },
                grid: {
                    display: false
                }
            }
        },
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                enabled: false,
            },
        },
        hover: {
            mode: 'nearest',
            axis: "x",
            intersect: false
        },
        onHover: (event, elements) => {
            // console.log('elements = ', elements)
            // if (elements.length) {
            //     const datasetIndex = elements[0].datasetIndex;
            //     const index = elements[0].index;
            //     const value = data.datasets[datasetIndex].data[index];
            //     // setHoveredData(value);
            // } else {
            //     // setHoveredData(null);
            // }
        },
    }

    return (
        <div className={'chart-section'}>
            <div style={{display: 'flex', justifyContent: 'space-between', alignContent: 'center', alignItems: 'center', padding: '10px 10px 0'}}>
                <div style={{display: 'flex'}}>
                    {/*<img style={{height: '24px'}} src={`https://cryptobubbles.net/backend/data/logos/${coinData.id}.png`} alt={coinData.symbol} />*/}
                    <div style={{marginLeft: '10px'}}><span>{coinData.symbol}</span></div>
                </div>
                <button className={'modal-close'} onClick={() => setVisible(null)}>
                    <svg style={{height: '24px', fill: 'white'}} viewBox="0 0 24 24">
                        <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
                    </svg>
                </button>
            </div>

            <div style={{display: "flex", justifyContent: 'center'}}>
                <div style={{textAlign: 'center'}}>
                    <div className={'section-title title-secondary'}>
                        Price
                    </div>
                    <div>
                        {roundToThreeSignificantDigits(coinData.price)} {getCurrencyValueByKey(currency)}
                        {/*4,56 млрд $*/}
                    </div>
                </div>

                <div style={{marginLeft: '20px', textAlign: 'center'}}>
                    <div className={'section-title title-secondary'}>
                        Market Cap
                    </div>
                    <div>
                        {numeral(coinData.marketcap).format('0.00 a')}
                        {/*4,56 млрд $*/}
                    </div>
                </div>

                <div style={{marginLeft: '20px', textAlign: 'center'}}>
                    <div className={'section-title title-secondary'}>
                        24h Volume
                    </div>
                    <div>
                        {numeral(coinData.volume).format('0.00 a')}
                        {/*201,36 млн $*/}
                    </div>
                </div>
            </div>

            {isChartLoading || error != null
                ? <div style={{height: '239px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    {isChartLoading
                        ? <span>Loading...</span>
                        : null
                    }

                    {error != null
                        ? <span>Error: {error}</span>
                        : null
                    }

                </div>
                : <>
                    {chartData != null
                        ? <div style={{ height: '239px' }}>
                            <Line
                                height={239}
                                ref={chartRef}
                                data={chartData}
                                options={options}
                                plugins={[crosshairLabel]}
                            >
                            </Line>

                        </div>
                        : null
                    }</>
            }


            <div style={{
                color: "#ccc",
                padding: '0 10px',
                display: 'flex',
                justifyContent: 'space-between'
            }}>
                <div>Дата:</div>
                <div>{crossDate}</div>
            </div>
            {/*<div><span>Цена: {crossPrice}</span></div>*/}

            <div style={{marginTop: '20px', display: 'flex', justifyContent: 'space-between', padding: '0 5px 5px'}}>
                {appType === AppType.CMC
                    ? <button className={`chart-period-button period-button cursor-pointer ${chartPeriod === Period.HOUR ? 'white-12' : 'white-0'}`} onClick={() => setChartPeriod(Period.HOUR)}>
                        <div className={'title-secondary'}>hour</div>
                        {coinData.performance.hour != null
                            ? <div className={`title-secondary ${coinData.performance.hour > 0 ? 'green' : 'red'}`}>{coinData.performance.hour}%</div>
                            : <div>-</div>
                        }
                    </button>
                    : null
                }
                <button className={`chart-period-button period-button cursor-pointer ${chartPeriod === Period.DAY ? 'white-12' : 'white-0'}`} onClick={() => setChartPeriod(Period.DAY)}>
                    <div className={'title-secondary'}>day</div>
                    {coinData.performance.day != null
                        ? <div className={`title-secondary ${coinData.performance.day > 0 ? 'green' : 'red'}`}>{coinData.performance.day}%</div>
                        : <div>-</div>
                    }
                </button>
                <button className={`chart-period-button period-button cursor-pointer ${chartPeriod === Period.WEEK ? 'white-12' : 'white-0'}`} onClick={() => setChartPeriod(Period.WEEK)}>
                    <div className={'title-secondary'}>week</div>
                    {coinData.performance.week != null
                        ? <div className={`title-secondary ${coinData.performance.week > 0 ? 'green' : 'red'}`}>{coinData.performance.week}%</div>
                        : <div>-</div>
                    }
                </button>
                <button className={`chart-period-button period-button cursor-pointer ${chartPeriod === Period.MONTH ? 'white-12' : 'white-0'}`} onClick={() => setChartPeriod(Period.MONTH)}>
                    <div className={'title-secondary'}>month</div>
                    {coinData.performance.month != null
                        ? <div className={`title-secondary ${coinData.performance.month > 0 ? 'green' : 'red'}`}>{coinData.performance.month}%</div>
                        : <div>-</div>
                    }
                </button>
                {appType === AppType.CMC
                    ? <button className={`chart-period-button period-button cursor-pointer ${chartPeriod === Period.YEAR ? 'white-12' : 'white-0'}`} onClick={() => setChartPeriod(Period.YEAR)}>
                        <div className={'title-secondary'}>year</div>
                        {coinData.performance.year != null
                            ? <div className={`title-secondary ${coinData.performance.year > 0 ? 'green' : 'red'}`}>{coinData.performance.year}%</div>
                            : <div>-</div>
                        }
                    </button>
                    : null
                }
            </div>

        </div>
    );
};
export default ChartPage;
