import React, {useEffect, useState} from 'react';
import {Constants} from "../../constants/constants";
import CoinService from "../../service/coin.service";
import BottomNavBarComponent from "../../component/bottom-nav-bar/bottom-nav-bar.component";
import LoadingComponent from "../../component/loading/loading.component";
const tg = window.Telegram.WebApp;

const EarnPage = () => {

    const userTelegramId = tg.initDataUnsafe.user?.id;

    const [rewardsData, setRewardsData] = useState(null);
    const [rewardsDataIsLoading, setRewardsDataIsLoading] = useState(false);
    const [rewardsDataError, setRewardsDataError] = useState(null);

    const [successRewardAd, setSuccessRewardAd] = useState(false);
    const [loadingAd, setLoadingAd] = useState(false);
    const [errorAd, setErrorAd] = useState(null);

    useEffect(() => {
        getRewards()
    }, [])

    async function getRewards() {
        if (userTelegramId == null) {
            setRewardsDataError('Earn work only in telegram version');
            return;
        }
        setRewardsDataError(null);
        setRewardsDataIsLoading(true)
        try {
            const rewardDataResponse = await CoinService.getRewardData(userTelegramId);
            if (rewardDataResponse.success) {
                setRewardsData({
                    coinCount: rewardDataResponse.rewardData.coinCount
                })
            } else {
                setRewardsDataError(rewardDataResponse.error);
            }
        } catch (e) {
            setRewardsDataError(e.message);
        }
        setRewardsDataIsLoading(false)
    }

    function startAdsgram() {
        const adController = window.Adsgram?.init({
            blockId: Constants.adsgramBlockId,
            debug: Constants.adsgramBlockDebug,
        });

        if (adController) {
            adController.show()
                .then(() => {
                    // user watch ad till the end
                    setSuccessRewardAd(true);
                })
                .catch((result) => {
                    // user get error during playing ad or skip ad
                    setErrorAd(result.description)
                });
        } else {
            // onError({
            //     error: true,
            //     done: false,
            //     state: 'load',
            //     description: 'Adsgram script not loaded',
            // });
            setErrorAd('Adsgram script not loaded')
        }
    }

    async function showAd() {
        if (loadingAd) {
            return;
        }

        try {
            if (userTelegramId == null) {
                setErrorAd('Earn ad work only in telegram version')
                return;
            }

            setLoadingAd(true);
            const rewardDataResponse = await CoinService.getRewardData(userTelegramId);

            if (!rewardDataResponse.success) {
                throw new Error(rewardDataResponse.error)
            }
            setLoadingAd(false);

            startAdsgram();

        } catch (e) {
            setLoadingAd(false);
            setErrorAd(e.message);
        }
    }

    return (
        <div className={'page'}>
            <div className={'page-container'}>
                <div className={'mb-10'}>
                    <div className={'title mb-5'}>Earn:</div>
                    <LoadingComponent isLoading={rewardsDataIsLoading} error={rewardsDataError}/>

                    {rewardsData
                        ? <>
                            <div>You earn {rewardsData.coinCount ?? 0} Coins</div>
                            <div className={'mb-20 content-settings-section-earn-text'}>
                                <a style={{color: '#ffffff90'}}
                                    href={'https://truthful-cheddar-de7.notion.site/Coin-Bubbles-11ea6ccfa2d48069be54e7b5c92e0390'}
                                    target={'_blank'}
                                >Airdrop description
                                </a>
                            </div>
                            <div className={'white-12 earn-item'} onClick={() => showAd()}>
                                Watch ad to Earn!
                                <div className={'earn-item-button'}>Go</div>
                            </div>
                            {loadingAd
                                ? <div className={'content-settings-section-earn-text'}>Loading...</div>
                                : null
                            }
                            {successRewardAd
                                ? <div className={'content-settings-section-earn-text green'}>Reward success! Coins will update after a few minutes</div>
                                : null
                            }
                            {errorAd != null
                                ? <div className={'content-settings-section-earn-text red'}>{errorAd}</div>
                                : null
                            }
                        </>
                        : null
                    }

                </div>
            </div>

            <BottomNavBarComponent activeLink={2}/>
        </div>
    );
}

export default EarnPage;
