import React, {useEffect, useState} from 'react';
import BottomNavBarComponent from "../../component/bottom-nav-bar/bottom-nav-bar.component";
import CoinService from "../../service/coin.service";
import LoadingComponent from "../../component/loading/loading.component";
const tg = window.Telegram.WebApp;

const FrensPage = () => {
    const userTelegramId = tg.initDataUnsafe.user?.id;

    const [friensData, setFrins] = useState(null);
    const [friensIsLoading, setFrinsIsLoading] = useState(false);
    const [friensError, setFrinsError] = useState(null);

    const [referralLinkIsCopied, setReferralLinkIsCopied] = useState(false);
    const [referralLinkIsCopiedError, setReferralLinkIsCopiedError] = useState(null);

    async function copyReferralLink() {
        if (userTelegramId == null) {
            setReferralLinkIsCopiedError('Invites work only in telegram version');
            return;
        }
        setReferralLinkIsCopiedError(null);
        await navigator.clipboard.writeText(`https://t.me/coinbubbles_bot/bubbles?startapp=ref_${userTelegramId}`);
        setReferralLinkIsCopied(true)
    }

    useEffect(() => {
        getFriens();
    }, []);

    async function getFriens() {
        if (userTelegramId == null) {
            setFrinsError('Friens work only in telegram version');
            return;
        }
        try {
            setFrinsError(null);
            setFrinsIsLoading(true);
            const response = await CoinService.getReferrals(userTelegramId);
            setFrins({
                referralsCount: response.referralsCount,
                referrals: response.referrals,
                referUsername: response.referUsername,
            })
        } catch (e) {
            setFrinsError(e.message);
        }
        setFrinsIsLoading(false);
    }

    return (
        <div className={'page'}>
            <div className={'page-container'}>
                <div className={'mb-10'}>
                    <div className={'title mb-5'}>Frens:</div>
                    <LoadingComponent isLoading={friensIsLoading} error={friensError}/>

                    {friensData
                        ? <>
                            <div className={'mb-10'}>You Invite from: {friensData.referUsername ?? "unowned"}</div>
                            <div className={'mb-5'}>{friensData.referralsCount} friens:</div>
                            {friensData.referrals.map((item, index) =>
                                <div className={'mb-5'} key={index + 1}>
                                    {item.name != null ? item.name : `User ${index + 1}`}
                                </div>
                            )}
                        </>
                        : null
                    }
                </div>
            </div>

            <BottomNavBarComponent activeLink={3} child={
                <>
                    <div style={{
                        padding: '5px 10px 0',
                        textAlign: 'center'
                    }}>
                        <button style={{marginBottom: '3px', width: '100%'}} className={`period-button cursor-pointer white-12`} onClick={() => copyReferralLink()}>
                            Invite a fren!
                        </button>
                        {referralLinkIsCopied
                            ? <div className={'content-settings-section-earn-text green'}>Copied!</div>
                            : null
                        }
                        {referralLinkIsCopiedError != null
                            ? <div className={'content-settings-section-earn-text red'}>{referralLinkIsCopiedError}</div>
                            : null
                        }
                    </div>
                </>
            }/>
        </div>
    );
};

export default FrensPage;
