const Constants = {
    baseUrl: "https://coinbubbles.info",
    // baseUrl: "http://localhost:3010",
    imageBaseUrl: "https://coinbubbles.info//api/v1/image", //"https://cryptobubbles.net/backend/",
    // imageBaseUrl: "http://localhost:3010/api/v1/image",
    adsgramBlockId: '3688',
    adsgramBlockDebug: false,
    cbAppVersion: '1.0.2',
};

const getCurrencyValueByKey = (val) => {
    if (val === "USD") {
        return "$"
    }
    return val;
}

const RagePage = {
    1: {
        start: 1,
        end: 100
    },
    2: {
        start: 101,
        end: 200
    },
    3: {
        start: 201,
        end: 300
    },
    4: {
        start: 301,
        end: 400
    },
    5: {
        start: 401,
        end: 500
    },
    6: {
        start: 501,
        end: 600
    },
    7: {
        start: 601,
        end: 700
    },
    8: {
        start: 701,
        end: 800
    },
    9: {
        start: 801,
        end: 900
    },
    10: {
        start: 901,
        end: 1000
    }
}

module.exports = {
    Constants,
    getCurrencyValueByKey,
    RagePage,
};
